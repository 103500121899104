var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-row",
        [
          _c("b-col", { staticClass: "mx-2 my-1" }, [
            _c("h4", [_vm._v("Procedures That Are A Justifiable Write Off")]),
          ]),
        ],
        1
      ),
      _c("table-component", {
        ref: "currentTable",
        attrs: {
          itemsRepo: _vm.procedureSettingsRepo,
          loadParams: { ProfilePlanContractId: _vm.$route.params.planId },
          isAddEditSidbar: true,
          isDataFromModel: false,
          fields: [
            { key: "cpt.procCode", label: "Procedure Code", sortable: true },
            { key: "cpt.description", label: "Description", sortable: true },
            { key: "action", label: "Actions" },
          ],
          localItems: _vm.currentItems,
          copyData: _vm.formData,
          sidebarData: _vm.formDataCopy,
          sidebarOpen: _vm.sidebar,
        },
        on: {
          "update:localItems": function ($event) {
            _vm.currentItems = $event
          },
          "update:local-items": function ($event) {
            _vm.currentItems = $event
          },
          "update:copyData": function ($event) {
            _vm.formData = $event
          },
          "update:copy-data": function ($event) {
            _vm.formData = $event
          },
          "update:sidebarData": function ($event) {
            _vm.formDataCopy = $event
          },
          "update:sidebar-data": function ($event) {
            _vm.formDataCopy = $event
          },
          "update:sidebarOpen": function ($event) {
            _vm.sidebar = $event
          },
          "update:sidebar-open": function ($event) {
            _vm.sidebar = $event
          },
          save: _vm.onSave,
        },
        scopedSlots: _vm._u([
          {
            key: "cell(Edit)",
            fn: function () {
              return [_c("div")]
            },
            proxy: true,
          },
          {
            key: "body",
            fn: function () {
              return [
                _c(
                  "b-container",
                  { staticClass: "px-2 mt-1 pb-3" },
                  [
                    _c(
                      "b-row",
                      [
                        _c(
                          "b-col",
                          [
                            _c("h4", [_vm._v("Add ProcedureCode")]),
                            _c(
                              "b-form-group",
                              {
                                staticClass: "mt-2",
                                attrs: { label: "Procedure Code" },
                              },
                              [
                                _c("validation-provider", {
                                  attrs: {
                                    name: "Procedure Code",
                                    rules: "required",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var errors = ref.errors
                                        return [
                                          _c("infinit-select", {
                                            attrs: {
                                              repo: _vm.procedureCodesRepo,
                                              label: "procCode",
                                            },
                                            model: {
                                              value: _vm.formDataCopy.cptId,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.formDataCopy,
                                                  "cptId",
                                                  $$v
                                                )
                                              },
                                              expression: "formDataCopy.cptId",
                                            },
                                          }),
                                          _c(
                                            "small",
                                            { staticClass: "text-danger" },
                                            [_vm._v(_vm._s(errors[0]))]
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }