var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("table-component", {
    ref: "table",
    attrs: {
      "items-repo": _vm.profileContractPriceRepo,
      "load-params": { ProfileContractId: +_vm.$route.params.planId },
      "is-data-from-model": false,
      "sidebar-data": _vm.formData,
      "copy-data": _vm.formDataCopy,
      "sidebar-open": _vm.sidebar,
      fields: _vm.fields,
      validate: true,
      "is-add-edit-sidbar": true,
      "do-save": false,
    },
    on: {
      "update:sidebarData": function ($event) {
        _vm.formData = $event
      },
      "update:sidebar-data": function ($event) {
        _vm.formData = $event
      },
      "update:copyData": function ($event) {
        _vm.formDataCopy = $event
      },
      "update:copy-data": function ($event) {
        _vm.formDataCopy = $event
      },
      "update:sidebarOpen": function ($event) {
        _vm.sidebar = $event
      },
      "update:sidebar-open": function ($event) {
        _vm.sidebar = $event
      },
      save: _vm.save,
      "on-open": function ($event) {
        _vm.description = ""
      },
    },
    scopedSlots: _vm._u([
      {
        key: "cell(modifiers)",
        fn: function (ref) {
          var item = ref.item
          return [
            _vm._l(item.priceDetails, function (detail) {
              return [
                detail.modifier
                  ? _c("div", [_vm._v(_vm._s(detail.modifier.code))])
                  : _c("div", [_vm._v(" NONE")]),
              ]
            }),
          ]
        },
      },
      {
        key: "cell(priceDetails)",
        fn: function (ref) {
          var item = ref.item
          return [
            _vm._l(item.priceDetails, function (detail) {
              return [
                detail.price
                  ? _c("div", [
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm.rateType === _vm.ContractRateType.Fixed
                              ? "$"
                              : _vm.rateType === _vm.ContractRateType.Percent
                              ? "%"
                              : null
                          ) +
                          "\n        " +
                          _vm._s(detail.price) +
                          "\n      "
                      ),
                    ])
                  : _c("div", [_vm._v(" 0")]),
              ]
            }),
          ]
        },
      },
      {
        key: "body",
        fn: function () {
          return [
            _c("ProcedurModPriceSidebar", {
              attrs: {
                "rate-type": _vm.rateType,
                "cpt-id": _vm.formDataCopy.cptId,
                "price-details": _vm.formDataCopy.priceDetails,
              },
              on: {
                "update:cptId": function ($event) {
                  return _vm.$set(_vm.formDataCopy, "cptId", $event)
                },
                "update:cpt-id": function ($event) {
                  return _vm.$set(_vm.formDataCopy, "cptId", $event)
                },
                "update:priceDetails": function ($event) {
                  return _vm.$set(_vm.formDataCopy, "priceDetails", $event)
                },
                "update:price-details": function ($event) {
                  return _vm.$set(_vm.formDataCopy, "priceDetails", $event)
                },
              },
            }),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }