var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-card",
        { attrs: { "no-body": "" } },
        [
          _c(
            "b-row",
            { staticClass: "px-2" },
            [
              _c(
                "b-col",
                {
                  staticClass: "my-1 pl-2 text-right",
                  attrs: { md: "12", sm: "12", xs: "12" },
                },
                [
                  _c(
                    "b-button",
                    {
                      attrs: {
                        type: "button",
                        variant: "outline-primary",
                        size: "sm",
                        pill: "",
                      },
                    },
                    [
                      _c("feather-icon", {
                        attrs: { icon: "UploadIcon", size: "16" },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-button",
                    {
                      staticClass: "ml-1",
                      attrs: {
                        type: "button",
                        variant: "outline-primary",
                        size: "sm",
                        pill: "",
                      },
                    },
                    [
                      _c("feather-icon", {
                        attrs: { icon: "DownloadIcon", size: "16" },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-button",
                    {
                      staticClass: "ml-1",
                      attrs: {
                        type: "button",
                        variant: "primary",
                        size: "sm",
                        pill: "",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.onRowSelected({}, "add")
                        },
                      },
                    },
                    [_vm._v("\n          + Add\n        ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { cols: "12" } },
                [
                  _c("b-table", {
                    attrs: {
                      selectable: "",
                      "select-mode": "single",
                      hover: "",
                      responsive: "",
                      "per-page": _vm.perPage,
                      "current-page": _vm.currentPage,
                      items: _vm.apiData.data.list,
                      fields: _vm.fields,
                      "sort-by": _vm.sortBy,
                      "sort-desc": _vm.sortDesc,
                      "sort-direction": _vm.sortDirection,
                      filter: _vm.filter,
                      "filter-included-fields": _vm.filterOn,
                    },
                    on: {
                      "update:sortBy": function ($event) {
                        _vm.sortBy = $event
                      },
                      "update:sort-by": function ($event) {
                        _vm.sortBy = $event
                      },
                      "update:sortDesc": function ($event) {
                        _vm.sortDesc = $event
                      },
                      "update:sort-desc": function ($event) {
                        _vm.sortDesc = $event
                      },
                      filtered: _vm.onFiltered,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "cell(procCode)",
                        fn: function (data) {
                          return [
                            _vm._v(
                              "\n            " +
                                _vm._s(data.item.procCode) +
                                "\n          "
                            ),
                          ]
                        },
                      },
                      {
                        key: "cell(name)",
                        fn: function (data) {
                          return [
                            _vm._v(
                              "\n            " +
                                _vm._s(data.item.name) +
                                "\n          "
                            ),
                          ]
                        },
                      },
                      {
                        key: "cell(description)",
                        fn: function (data) {
                          return [
                            _vm._v(
                              "\n            " +
                                _vm._s(data.item.description) +
                                "\n          "
                            ),
                          ]
                        },
                      },
                      {
                        key: "cell(action)",
                        fn: function (data) {
                          return [
                            _c(
                              "div",
                              { staticClass: "d-flex" },
                              [
                                _c(
                                  "b-button",
                                  {
                                    directives: [
                                      {
                                        name: "ripple",
                                        rawName: "v-ripple.400",
                                        value: "rgba(40, 199, 111, 0.15)",
                                        expression:
                                          "'rgba(40, 199, 111, 0.15)'",
                                        modifiers: { 400: true },
                                      },
                                    ],
                                    staticClass: "d-flex px-0",
                                    attrs: {
                                      variant: "flat-primary",
                                      size: "sm",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.onRowSelected(
                                          data.item,
                                          "edit"
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("feather-icon", {
                                      attrs: { icon: "EditIcon", size: "16" },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-button",
                                  {
                                    directives: [
                                      {
                                        name: "ripple",
                                        rawName: "v-ripple.400",
                                        value: "rgba(40, 199, 111, 0.15)",
                                        expression:
                                          "'rgba(40, 199, 111, 0.15)'",
                                        modifiers: { 400: true },
                                      },
                                    ],
                                    staticClass: "float-right px-0 ml-1",
                                    attrs: {
                                      variant: "flat-danger",
                                      size: "sm",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.deleteRow(data.item.id)
                                      },
                                    },
                                  },
                                  [
                                    _c("feather-icon", {
                                      attrs: { icon: "Trash2Icon", size: "16" },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { staticClass: "pb-1 pl-2", attrs: { cols: "3" } },
                [
                  _c(
                    "label",
                    { staticClass: "d-inline-block text-sm-left mr-50 pl-2" },
                    [_vm._v("Show")]
                  ),
                  _c("b-form-select", {
                    staticClass: "w-50",
                    attrs: {
                      id: "perPageSelect",
                      size: "sm",
                      options: _vm.pageOptions,
                    },
                    model: {
                      value: _vm.perPage,
                      callback: function ($$v) {
                        _vm.perPage = $$v
                      },
                      expression: "perPage",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { staticClass: "pb-1", attrs: { cols: "9" } },
                [
                  _c("b-pagination", {
                    staticClass: "my-0 pr-2",
                    attrs: {
                      "total-rows": _vm.totalRows,
                      "per-page": _vm.perPage,
                      align: "right",
                      size: "sm",
                    },
                    model: {
                      value: _vm.currentPage,
                      callback: function ($$v) {
                        _vm.currentPage = $$v
                      },
                      expression: "currentPage",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "validation-observer",
        { ref: "simpleRules" },
        [
          _c(
            "b-form",
            [
              _c(
                "b-sidebar",
                {
                  attrs: {
                    id: "sidebar-right",
                    "bg-variant": "white",
                    "no-header": "",
                    right: "",
                    backdrop: "",
                    shadow: "",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "footer",
                      fn: function () {
                        return [
                          _c(
                            "b-container",
                            [
                              _c(
                                "b-row",
                                [
                                  _c("b-col", [
                                    _c(
                                      "div",
                                      { staticClass: "w-100 text-right pb-2" },
                                      [
                                        _vm.mode == "edit"
                                          ? _c(
                                              "b-button",
                                              {
                                                directives: [
                                                  {
                                                    name: "ripple",
                                                    rawName: "v-ripple.400",
                                                    value:
                                                      "rgba(255, 255, 255, 0.15)",
                                                    expression:
                                                      "'rgba(255, 255, 255, 0.15)'",
                                                    modifiers: { 400: true },
                                                  },
                                                ],
                                                attrs: {
                                                  variant: "flat-danger",
                                                  size: "sm",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.deleteRow(
                                                      _vm.selectedRow.id
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass: "align-middle",
                                                  },
                                                  [_vm._v("Delete")]
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _c(
                                          "b-button",
                                          {
                                            directives: [
                                              {
                                                name: "ripple",
                                                rawName: "v-ripple.400",
                                                value:
                                                  "rgba(255, 255, 255, 0.15)",
                                                expression:
                                                  "'rgba(255, 255, 255, 0.15)'",
                                                modifiers: { 400: true },
                                              },
                                            ],
                                            staticClass: "ml-1 mr-1",
                                            attrs: {
                                              variant: "outline-primary",
                                              size: "sm",
                                            },
                                            on: {
                                              click: function ($event) {
                                                _vm.sidebar = false
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                    Cancel\n                  "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "b-button",
                                          {
                                            directives: [
                                              {
                                                name: "ripple",
                                                rawName: "v-ripple.400",
                                                value:
                                                  "rgba(255, 255, 255, 0.15)",
                                                expression:
                                                  "'rgba(255, 255, 255, 0.15)'",
                                                modifiers: { 400: true },
                                              },
                                            ],
                                            attrs: {
                                              variant: "primary",
                                              size: "sm",
                                              type: "submit",
                                            },
                                            on: {
                                              click: function ($event) {
                                                $event.preventDefault()
                                                return _vm.validationForm.apply(
                                                  null,
                                                  arguments
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("feather-icon", {
                                              staticClass: "mr-50",
                                              attrs: { icon: "CheckIcon" },
                                            }),
                                            _c(
                                              "span",
                                              { staticClass: "align-middle" },
                                              [_vm._v("Done")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                  model: {
                    value: _vm.sidebar,
                    callback: function ($$v) {
                      _vm.sidebar = $$v
                    },
                    expression: "sidebar",
                  },
                },
                [
                  _c(
                    "b-container",
                    { staticClass: "px-2 mt-1 pb-3" },
                    [
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            [
                              _vm.mode == "edit"
                                ? _c("h4", [_vm._v("Edit Fixed Amounts")])
                                : _c("h4", [_vm._v("Add Fixed Amounts")]),
                              _c(
                                "b-form-group",
                                {
                                  staticClass: "mt-2",
                                  attrs: { label: "Procedure Code" },
                                },
                                [
                                  _c("validation-provider", {
                                    attrs: {
                                      name: "Procedure Code",
                                      rules: "required",
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function (ref) {
                                          var errors = ref.errors
                                          return [
                                            _c("b-form-input", {
                                              attrs: {
                                                placeholder: "",
                                                required: "",
                                              },
                                              model: {
                                                value: _vm.selectedRow.procCode,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.selectedRow,
                                                    "procCode",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "selectedRow.procCode",
                                              },
                                            }),
                                            _c(
                                              "small",
                                              { staticClass: "text-danger" },
                                              [_vm._v(_vm._s(errors[0]))]
                                            ),
                                          ]
                                        },
                                      },
                                    ]),
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "b-form-group",
                                {
                                  staticClass: "mt-2",
                                  attrs: { label: "Name" },
                                },
                                [
                                  _c("validation-provider", {
                                    attrs: { name: "Name", rules: "required" },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function (ref) {
                                          var errors = ref.errors
                                          return [
                                            _c("b-form-input", {
                                              attrs: {
                                                placeholder: "",
                                                required: "",
                                              },
                                              model: {
                                                value: _vm.selectedRow.name,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.selectedRow,
                                                    "name",
                                                    $$v
                                                  )
                                                },
                                                expression: "selectedRow.name",
                                              },
                                            }),
                                            _c(
                                              "small",
                                              { staticClass: "text-danger" },
                                              [_vm._v(_vm._s(errors[0]))]
                                            ),
                                          ]
                                        },
                                      },
                                    ]),
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "b-form-group",
                                {
                                  staticClass: "mt-2",
                                  attrs: { label: "Description" },
                                },
                                [
                                  _c("validation-provider", {
                                    attrs: {
                                      name: "Description",
                                      rules: "required",
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function (ref) {
                                          var errors = ref.errors
                                          return [
                                            _c("b-form-input", {
                                              attrs: {
                                                placeholder: "",
                                                required: "",
                                              },
                                              model: {
                                                value:
                                                  _vm.selectedRow.description,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.selectedRow,
                                                    "description",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "selectedRow.description",
                                              },
                                            }),
                                            _c(
                                              "small",
                                              { staticClass: "text-danger" },
                                              [_vm._v(_vm._s(errors[0]))]
                                            ),
                                          ]
                                        },
                                      },
                                    ]),
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "b-row",
                                [
                                  _c(
                                    "b-col",
                                    { attrs: { cols: "6" } },
                                    [
                                      _c(
                                        "b-form-group",
                                        {
                                          staticClass: "mt-2",
                                          attrs: { label: "Amount" },
                                        },
                                        [
                                          _c("validation-provider", {
                                            attrs: {
                                              name: "Amount",
                                              rules: "required",
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "default",
                                                fn: function (ref) {
                                                  var errors = ref.errors
                                                  return [
                                                    _c(
                                                      "b-input-group",
                                                      {
                                                        staticClass:
                                                          "input-group-merge",
                                                      },
                                                      [
                                                        _c("b-form-input", {
                                                          attrs: {
                                                            type: "number",
                                                            required: "",
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.selectedRow
                                                                .amount,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.selectedRow,
                                                                "amount",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "selectedRow.amount",
                                                          },
                                                        }),
                                                        _c(
                                                          "b-input-group-append",
                                                          {
                                                            attrs: {
                                                              "is-text": "",
                                                            },
                                                          },
                                                          [
                                                            _c("feather-icon", {
                                                              attrs: {
                                                                icon: "DollarSignIcon",
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "small",
                                                      {
                                                        staticClass:
                                                          "text-danger",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(errors[0])
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                              },
                                            ]),
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }