


























































































































































































































































































import { Vue, Component, Ref } from 'vue-property-decorator';
import Procedures from '../../../../../../modules/Procedures';
import Ripple from 'vue-ripple-directive'
import {
  BAvatar, BInputGroupAppend, BSidebar, BCard, BTable, BBadge, BContainer, BRow, BCol, BForm, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BButton,
} from 'bootstrap-vue'
import {
  ValidationProvider,
  ValidationObserver
} from 'vee-validate/dist/vee-validate.full';
import { ContactMandatoryTypes } from '../../../../../../types/procedureTypes';

@Component({
  components: {
    BSidebar,
    BTable,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BForm,
    BPagination,
    BInputGroup,
    BFormInput,
    BButton,
    BCard,
    BContainer,
    ValidationProvider,
    ValidationObserver,
    BInputGroupAppend,
    BAvatar,
  },
  directives: {
    Ripple,
  }
})
export default class PercentCC extends Vue {

  // DATA
  @Ref('simpleRules') readonly refs_simpleRules!: any;
  procedures = new Procedures();
  apiData:ApiMethodResponse<ContactMandatoryTypes> = {
    message: '',
    data: {
      list: [],
      rowsCount: 0,
    },
  };
  selectedRow:ContactMandatoryTypes = {
    id: null,
    procCode: null,
    name: null,
    description: null,
    amount: null
  };
  contractType = null;
  medicare = null;
  feeSchedule = null;
  mode = '';
  result:string = '';
  sidebar:boolean = false;
  statusChange = null;
  perPage = 5;
  pageOptions = [3, 5, 10];
  totalRows = 1;
  currentPage = 1;
  sortBy = '';
  sortDesc = false;
  sortDirection = 'asc';
  filter = null;
  filterOn = [];
  selector = null;
  fields = [
    { key: 'procCode', label: 'procedure code', sortable: true },
    { key: 'name', label: 'Name', sortable: true },
    { key: 'description', label: 'description', sortable: true },
    { key: 'amount', label: 'amount + percent', sortable: true },
    { key: 'action', label: 'Actions'},
  ];

  // COMPUTED

  // METHODS
  onRowSelected(rowItem, mode) {
    this.mode = mode;
    if(mode == 'add') {
      this.selectedRow  = {
        id: null,
        procCode: null,
        name: null,
        description: null,
        amount: null
      }
    } else {
      this.selectedRow = { ...rowItem };
    }
    this.sidebar = true;
  }
  deleteRow(id) {
    alert("Delete " + id);
    this.sidebar = false;
    // this.result = this.settings.deleteClearingHouses(id);
  }
  validationForm() {
    this.refs_simpleRules.validate().then(success => {
      if (success) {
        // eslint-disable-next-line
        alert('form submitted!');
        if(this.mode == 'add') {
          // this.result = this.practices.addClearingHouses(this.selectedRow);
        } else if (this.mode == 'edit') {
          // this.result = this.practices.updateClearingHouses('', this.selectedRow);
        }
      }
    })
  }
  onFiltered(filteredItems: any) {
    this.totalRows = filteredItems.length
    this.currentPage = 1
  }

  // CREATED
  async created() {
    this.apiData = this.procedures.getContactMandatory('id');
  }

  // MOUNTED
  mounted() {
    this.totalRows = this.apiData.data.list!.length
  }
}
